@use "@angular/material" as mat;

@mixin theme($theme) {
  mat-card.mat-mdc-card-outlined  {
    background-color: #fff !important;
  }
}

@mixin stepper-theme($theme) {
  mat-stepper {
    background-color: #fff !important;
  }
}
