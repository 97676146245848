@use "@angular/material" as mat;

@mixin theme($theme) {

    .mat-toolbar {
        background-color: rgba(mat.get-theme-color($theme, primary-container), 0.2);

        button.active-button {
            box-sizing: border-box;
            border: 2px solid mat.get-theme-color($theme, primary-container);
        }
    }
}
