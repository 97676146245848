@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Symbols+Outlined");
@import 'theme/form-groups';
@import 'theme/form_container';
@import 'theme/badge';
@import 'theme/typography';

:root {
    --primary-color: #000000;
    --secondary-color: #4FFF83;
    --secondary-800: #00bc1e;
    --primary-50: #e6ffeb;
    --black-color: #212529;
    --white-color: #ffffff;
    --folder-icon-color: #5f6368;
    --file-icon-color: #1A73E8;
    --red-color: #DC3545;
    --green-color: #198754;
    --yellow-color: #ffc107;
    --blue-color: #0d6efd;
    --blue-color-50: rgba(163, 199, 255, 0.44);
    --gray-color: #6c757d;
    --orange-color: #fd7e14;
    --border-color: #E0E0E0;
    --light-blue-color: #0dcaf0;
}

html, body {
    height: 100%;
    font-size: 16px;
    color: var(--black-color);

}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif !important;
}

.pointer {
    cursor: pointer;
}

.mat-typography {
    font-family: Roboto, "Helvetica Neue", sans-serif !important;
    font-size: 16px !important;
}

.bg-secondary {
    background-color: var(--secondary-color) !important;
}

.bg-red {
    background-color: var(--red-color);
}

.bg-green {
    background-color: var(--green-color);
}

.bg-blue {
    background-color: var(--blue-color);
}

.bg-light-blue {
    background-color: var(--light-blue-color);
}

.bg-orange {
    background-color: var(--orange-color);
}

.bg-yellow {
    background-color: var(--yellow-color);
}

.bg-gray {
    background-color: var(--gray-color);
}

.bg-invalid-history {
    background-color: #ccc !important;
}

.cursor-default {
    cursor: default !important;
}

.icon-column {
    width: 40px;
}

.mat-mdc-dialog-content {
    padding: 1.5rem !important;
}

