.text-sm {
    font-size: .875rem !important;
}

mat-icon.text-sm, img.text-sm {
    font-size: .875rem !important;
    height: .875rem !important;
    width: .875rem !important;
    line-height: .875rem !important;
}

.text-xs {
    font-size: .75rem !important;
}

mat-icon.text-xs, img.text-xs {
    font-size: .75rem !important;
    height: .75rem !important;
    width: .75rem !important;
    line-height: .75rem !important;
}

.text-xxs {
    font-size: .625rem !important;
}

mat-icon.text-xxs, img.text-xxs {
    font-size: .625rem !important;
    height: .625rem !important;
    width: .625rem !important;
    line-height: .625rem !important;
}

.text-xxxs {
    font-size: .5rem !important;
}

mat-icon.text-xxxs, img.text-xxxs {
    font-size: .5rem !important;
    height: .5rem !important;
    width: .5rem !important;
    line-height: .5rem !important;
}

.text-normal {
    font-size: 1rem !important;
}

mat-icon.text-normal, img.text-normal {
    font-size: 1rem !important;
    height: 1rem !important;
    width: 1rem !important;
    line-height: 1rem !important;
}

.text-lg {
    font-size: 1.25rem !important;
}

mat-icon.text-lg, img.text-lg {
    font-size: 1.25rem !important;
    height: 1.25rem !important;
    width: 1.25rem !important;
    line-height: 1.25rem !important;
}

.text-xl {
    font-size: 1.5rem !important;
}

mat-icon.text-xl, img.text-xl {
    font-size: 1.5rem !important;
    height: 1.5rem !important;
    width: 1.5rem !important;
    line-height: 1.5rem !important;
}

.text-xxl {
    font-size: 2rem !important;
}

mat-icon.text-xxl, img.text-xxl {
    font-size: 2rem !important;
    height: 2rem !important;
    width: 2rem !important;
    line-height: 2rem !important;
}
