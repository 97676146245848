// _table-theme.scss
@use '@angular/material' as mat;
@use 'sass:map';

@mixin table-theme($theme) {
    $primary: map.get($theme, 'primary');

    .mat-mdc-table {
        width: 100%;
        background-color: rgba(mat.get-theme-color($theme, surface), 0.95);

        // Header styling
        .mat-mdc-header-row {
            background-color: rgba(mat.get-theme-color($theme, primary-container), 0.15);
            height: 48px;

            .mat-mdc-header-cell {
                color: rgba(mat.get-theme-color($theme, on-surface-variant), 0.87);
                font-weight: 500;
                border-bottom-color: rgba(mat.get-theme-color($theme, outline-variant), 0.6);
                padding: .25rem .5rem;
            }
        }

        // Column widths for action and expand columns
        .mat-column-actions,
        .mat-column-expand {
            width: 48px;
            padding: 0 4px;
        }

        .mat-column-actions {
            text-align: right;
            padding-right: 0;
        }

        .mat-column-expand {
            padding-left: 0;
        }

        .mat-row.mat-mdc-no-data-row {
            background-color: #fff;

            td {
                padding: 1rem;
                text-align: center;
            }
        }

        .mat-mdc-row {
            height: 52px;
            transition: background-color 0.2s ease;
            //cursor: pointer;  // Add cursor pointer to indicate interactivity

            // Remove individual cell hover
            .mat-mdc-cell {
                color: rgba(mat.get-theme-color($theme, on-surface), 0.87);
                border-bottom-color: rgba(mat.get-theme-color($theme, outline-variant), 0.38);
                padding: .25rem .5rem; // Added padding to cells

            }

            &:nth-child(even) {
                //background-color: rgba(mat.get-theme-color($theme, surface), 0.98);
                background-color: #fff;
            }

            &:nth-child(odd) {
                //background-color: rgba(mat.get-theme-color($theme, surface-variant), 0.02);
                background-color: #fff;
            }

            // Row hover effect
            &:hover {
                background-color: rgba(mat.get-theme-color($theme, primary-container), 0.04) !important;
            }
        }
        // Cell styling
        .mat-mdc-cell {
            color: rgba(mat.get-theme-color($theme, on-surface), 0.87);  // Slightly lighter text
            border-bottom-color: rgba(mat.get-theme-color($theme, outline-variant), 0.38);  // Much lighter border
        }

        // Selected row styling - keep this more visible
        .mat-mdc-row.selected {
            background-color: rgba(mat.get-theme-color($theme, primary-container), 0.08);

            .mat-mdc-cell {
                color: rgba(mat.get-theme-color($theme, on-primary-container), 0.87);
            }

            &:hover {
                background-color: rgba(mat.get-theme-color($theme, primary-container), 0.12);
            }
        }

        // Detail row styling
        tr.detail-row {
            height: 0 !important;

            td {
                padding: 0 !important;
                border: none !important;
            }

            .detail-container {
                cursor: default;
                overflow: hidden;
                background-color: rgba(mat.get-theme-color($theme, surface-container), 0.35);

                &.ng-animating {
                    transition: all 225ms cubic-bezier(0.4, 0.0, 0.2, 1);
                }
            }
        }

        // Expandable row styling
        tr.expandable-row {
            height: 48px;
            transition: background-color 0.3s ease;
            cursor: pointer;

            .mat-mdc-cell {
                height: 52px;
                color: rgba(mat.get-theme-color($theme, on-surface), 0.87);
                border-bottom-color: rgba(mat.get-theme-color($theme, outline-variant), 0.38);
                padding: .25rem .5rem;
            }

            &:nth-child(even) {
                //background-color: rgba(mat.get-theme-color($theme, surface), 0.98);
                background-color: #fff;
            }

            &:nth-child(odd) {
                //background-color: rgba(mat.get-theme-color($theme, surface-variant), 0.02);
                background-color: #fff;
            }

            &:not(.expanded-row) {
                &:hover {
                    background-color: rgba(mat.get-theme-color($theme, primary-container), 0.04) !important;
                }

                &:active {
                    background-color: rgba(mat.get-theme-color($theme, primary-container), 0.08);
                }
            }

            &.expanded-row {
                //background-color: rgba(mat.get-theme-color($theme, primary-container), 0.08);
                background-color: #fff;

                td {
                    border-bottom: none;
                }

                &:hover {
                    background-color: rgba(mat.get-theme-color($theme, primary-container), 0.12);
                }
            }
        }

        // Pagination and other styles remain the same...
    }

    // Animation styles
    .detail-container {
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }

    @keyframes expandCollapse {
        0% {
            height: 0;
            min-height: 0;
        }
        100% {
            height: var(--expanded-height);
            min-height: var(--expanded-height);
        }
    }
}
