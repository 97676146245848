@use "@angular/material" as mat;
@use "sass:map";

@mixin whisper-theme($theme) {
    .ng-select .ng-select-container {
        border: 1px solid #8f8c8c !important;
        transition: border 0.2s ease;
    }
    //lighten(mat.get-theme-color($theme, primary-container), 25%)
    .ng-select.ng-select-focused .ng-select-container {
        border: 2px solid darken(mat.get-theme-color($theme, primary-container), 50%) !important;
    }

    .ng-select.ng-invalid.ng-touched .ng-select-container {
        border: 1px solid darken(mat.get-theme-color($theme, primary-container), 50%) !important;
    }

    .ng-select:hover .ng-select-container {
        border-color: darken(mat.get-theme-color($theme, primary-container), 40%) !important;
    }
}
