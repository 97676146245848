
@use '@angular/material' as mat;
@use 'sass:map';
@use "components/_layout-component.theme";
@use "components/_card.theme";
@use "form-field";
@use "whisper";
@use "table-theme";
@use "components/_navbar-component.theme";
@use "components/tab.theme";
@use "mat-menu";

@include mat.core();

// Define themes for different primary colors
@mixin generate-theme($primary-palette) {
    $theme: mat.define-theme((
        color: (
            theme-type: light,
            primary: $primary-palette,
            tertiary: mat.$red-palette,
        ),
        density: (
            scale: 0,
        )
    ));

    @include mat.all-component-themes($theme);
    @include layout-component.theme($theme);
    @include card.theme($theme);
    @include card.stepper-theme($theme);
    @include whisper.whisper-theme($theme);
    @include table-theme.table-theme($theme);
    @include form-field.compact-form-field-height();
    @include navbar-component.theme($theme);
    @include tab.tab-link-theme($theme);
    @include mat-menu.menu-theme($theme)
}

// Default theme
:root {
    @include generate-theme(mat.$spring-green-palette);
}

// Alternative themes
$themes: (
    'azure': mat.$azure-palette,
    'violet': mat.$violet-palette,
    'neutral': mat.$azure-palette,
    'red': mat.$red-palette,
    'green': mat.$green-palette,
    'blue': mat.$blue-palette,
    'magenta': mat.$magenta-palette,
    'orange': mat.$orange-palette,
    'spring-green': mat.$spring-green-palette,
    'chartreuse': mat.$chartreuse-palette,
    'cyan': mat.$cyan-palette,
    'yellow': mat.$yellow-palette,
    'rose': mat.$rose-palette
);

@each $name, $palette in $themes {
    .theme-#{$name} {
        @include generate-theme($palette);
    }
}
