.badge-light-green {
    background-color: #93ffa5;
    color: #1b5e20 !important;
}

.badge-light-red {
    background-color: #ff9e9e;
    color: #921e31 !important;
}

.badge-light-blue {
    background-color: #9ee0ff;
    color: #01579b !important;
}

.badge-light-yellow {
    background-color: #fffa9e;
    color: #f57f17 !important;
}

.badge-light-orange {
    background-color: #ffcc9e;
    color: #e65100 !important;
}

.badge-light-purple {
    background-color: #9e9eff;
    color: #4a148c !important;
}

.badge-light-gray {
    background-color: #e0e0e0;
    color: #212121 !important;
}

.badge + .badge {
    margin-left: .5rem;
}
