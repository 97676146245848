// _form-field.scss
@use 'sass:map';
@use '@angular/material' as mat;

@mixin compact-form-field-height() {
    .mat-mdc-form-field:not(.textarea) {
        &.mat-mdc-form-field-type-mat-input,
        &.mat-mdc-form-field-type-mat-select {
            // Adjust overall height
            height: 36px;
            background: #fff;

            .mat-mdc-text-field-wrapper {
                height: 36px;
                background-color: var(--sys-surface-container-lowest);
                padding: 0;

                .mat-mdc-form-field-flex {
                    height: 36px;
                    min-height: 36px;
                    align-items: center;
                    background-color: var(--sys-surface-container-lowest);
                    padding: 0 8px;

                    .mdc-text-field--outlined {
                        height: 36px;
                        min-height: 36px;
                        background-color: var(--sys-surface-container-lowest);
                    }

                    .mat-mdc-select-trigger {
                        height: 36px;
                    }
                }
            }

            // Input adjustments
            .mat-mdc-form-field-infix {
                padding: 0;
                min-height: 36px;
                height: 36px;
                background-color: var(--sys-surface-container-lowest);

                input.mat-mdc-input-element,
                .mat-mdc-select-value-text {
                    height: 36px;
                    line-height: 36px;
                    margin: 0;
                }
            }

            // Field background and border
            .mdc-text-field:not(.mdc-text-field--textarea) {
                height: 36px;
                min-height: 36px;
                background-color: var(--sys-surface-container-lowest);
                padding: 0;

                &:not(.mdc-text-field--disabled) {
                    .mdc-notched-outline__leading,
                    .mdc-notched-outline__notch,
                    .mdc-notched-outline__trailing {
                        border-color: var(--sys-outline-variant);
                    }
                }
            }

            // Outline adjustments
            .mdc-notched-outline {
                background-color: var(--sys-surface-container-lowest);

                .mdc-notched-outline__leading,
                .mdc-notched-outline__notch,
                .mdc-notched-outline__trailing {
                    border-width: 1px !important;
                    border-color: var(--sys-outline-variant) !important;
                }

                .mdc-notched-outline__leading {
                    border-radius: 4px 0 0 4px !important;
                    border-right-width: 0 !important;
                    width: 8px !important;
                }

                .mdc-notched-outline__notch {
                    border-left: none;
                    border-right: none;
                    padding: 0;
                }

                .mdc-notched-outline__trailing {
                    border-radius: 0 4px 4px 0 !important;
                    border-left-width: 0 !important;
                }
            }

            // Label positioning
            .mat-mdc-floating-label {
                top: 50%;
                transform: translateY(-50%);
                margin: 0;
                padding: 0 4px;

                &.mdc-floating-label--float-above {
                    transform: translateY(-130%) scale(0.75);
                    background-color: var(--sys-surface-container-lowest);
                }
            }

            // Focused state
            &.mat-focused {
                .mdc-notched-outline__leading,
                .mdc-notched-outline__notch,
                .mdc-notched-outline__trailing {
                    border-width: 2px !important;
                    border-color: var(--sys-primary) !important;
                }

                .mat-mdc-floating-label {
                    color: var(--sys-primary);
                }
            }

            // Error state
            &.mat-form-field-invalid {
                .mdc-notched-outline__leading,
                .mdc-notched-outline__notch,
                .mdc-notched-outline__trailing {
                    border-color: var(--sys-error) !important;
                }

                .mat-mdc-floating-label {
                    color: var(--sys-error);
                }
            }

            // Disabled state
            &.mat-form-field-disabled {
                .mdc-notched-outline__leading,
                .mdc-notched-outline__notch,
                .mdc-notched-outline__trailing {
                    border-color: var(--sys-outline-variant);
                    opacity: 0.38;
                }
            }
        }
    }
    .mat-mdc-form-field-icon-prefix > .mat-icon, .mat-mdc-form-field-icon-suffix > .mat-icon {
        padding: 0 !important;
    }
}
