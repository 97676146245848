@use 'sass:map';
@use '@angular/material' as mat;

.form-group {
    > label {
        font-size: .875rem;
        font-weight: bold;
    }

    + .form-group:not(.col-md-6), + *:not(.col-md-6) {
        margin-top: 1rem;
    }

    .custom-form-field {
        width: 100%;
    }
}
