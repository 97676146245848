@use '@angular/material' as mat;
@use 'sass:map';

@mixin menu-theme($theme) {
    // Extract theme colors
    $surface: map.get($theme, 'surface');

    // Style the menu panel
    .mat-mdc-menu-panel {
        background-color: #fff;

        // Style menu items
        .mat-mdc-menu-content {
            padding: 0;
            .mat-mdc-menu-item {
                &:hover {
                    background-color: rgba(mat.get-theme-color($theme, primary-container), 0.04);
                }
            }
        }
    }

    // Custom class for tag selector menu
    .tags-mat-menu {
        .mat-mdc-menu-panel {
            background-color: #fff;

            // Override max-height for tag menus that might need more space
            max-height: 500px !important;

            // Add some padding to the menu content
            .mat-mdc-menu-content {
                padding: .75rem;
            }
        }
    }
}
