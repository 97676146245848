@use "@angular/material" as mat;

@mixin theme($theme) {
  .sidenav-scroll-wrapper {
      @media (pointer: fine) {
          &::-webkit-scrollbar-thumb {
              background-color: mat.get-theme-color($theme, primary);
          }
      }
  }

    .app-layout-container {
        display: flex;
        flex-direction: column;
        height: 100vh;

        mat-drawer-container {
            flex: 1;
            height: calc(100vh - 64px);
            margin-top: 64px;
        }

        mat-drawer {
            width: 0px; // Collapsed width
            transition: width 0.3s ease;
            padding-top: 1rem;
            border-right: 1px solid var(--sys-outline-variant);

            &.expanded {
                width: 250px;
            }

            .entry {
                display: flex;
                align-items: center;
                gap: 1rem;
                padding: 0.75rem;
            }

            &:not(.expanded) {
                visibility: hidden;
                pointer-events: none;
            }
        }

        mat-drawer-content {
            display: flex;
            flex-direction: column;
            height: 100%;
            transition: margin-left 0.3s ease;
            margin-left: 0 !important;

            &.content-shifted {
                margin-left: 250px !important;
            }
        }

        .sidenav-scroll-wrapper {
            flex: 1;
            height: calc(100vh - 128px); // Subtract navbar and footer
            overflow-y: auto;
            padding: 1rem;
            box-sizing: border-box;
        }

        app-footer {
            height: 64px;
            flex-shrink: 0;
        }

        // Overlay for mobile
        .overlay {
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 1;

            &.show {
                display: block;
            }
        }

        // Mobile adjustments
        @media (max-width: 800px) {
            mat-drawer {
                position: fixed;
                padding-top: 3.5rem;
            }

            mat-drawer-content {
                margin-left: 0 !important;
            }

            .sidenav-scroll-wrapper {
                padding-top: 0;
            }
        }
    }

}
