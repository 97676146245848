@use 'sass:map';
@use '@angular/material' as mat;

.form-container {
    max-width: 800px;
    margin: 0;
    padding: 1.5rem;
    //background: #fff;
    //border-radius: 8px;
    //box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    h1 {
        text-align: center;
        margin-bottom: 20px;
    }

    section {
        margin-bottom: 1rem;
    }
}
